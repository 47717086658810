import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t, c, n } from 'ab18n'
import { Card, List } from 'antd'

import { actions as actionLogin } from 'pages/auth/actions'
import { actions as actionsUnits } from 'pages/units/actions'
import RenderList from 'components/RenderList'
import { actions } from './actions'

const mapStateToProps = ({ products, units }) => ({ products, units })
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...actionLogin,
  ...actionsUnits,
}, dispatch)

class ProductsPrices extends Component {
  componentDidMount() {
    const {
      units, match, fetchProducts, fetchUnits,
    } = this.props
    const unitId = match.params.id

    if (!units.data) {
      fetchUnits()
    }

    fetchProducts(unitId)
  }

  handleInputChange = (item, price) => {
    const { postItem, match } = this.props
    const unitId = match.params.id

    if (item.price !== n(price)) {
      postItem({
        ...item,
        price: n(price),
        isActive: 1,
        unitId,
      })
    }
  }

  render() {
    const { products, units, match } = this.props
    let unitEmail = ''

    if (units.data && match.params.id) {
      unitEmail = units.data.find(unit => unit.id === +match.params.id).email
    }

    return (
      <Card title={`${t('products.title')}: ${unitEmail}`}>
        <div className="list">
          {
            products.data
            && (
            <List
              size="small"
              dataSource={products.data}
              loading={products.loading}
              renderItem={item => (
                <RenderList
                  {...item}
                  value={c(item.price)}
                  width={150}
                  type="currency"
                  handleInputChange={this.handleInputChange}
                />
              )}
            />
            )
          }
        </div>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPrices)
