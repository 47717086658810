import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t, c } from 'ab18n'
import moment from 'moment'
import {
  Card,
  Modal,
  Button,
  List,
  Tooltip,
  Badge,
} from 'antd'

import status from 'shared/status'
import { TableComponent } from 'components'
import getColumnSearchProps from 'shared/getColumnSearchProps'
import search from 'shared/search'
import { actions as actionLogin } from 'pages/auth/actions'
import { actions as actionsUnits } from 'pages/units/actions'
import { actions as productsActions } from 'pages/products/actions'
import { actions } from './actions'
import AddNew from './AddNew'
import './ProductPurchase.sass'

const columns = () => ([
  {
    dataIndex: 'typeFormatted',
    key: 'typeFormatted',
    align: 'center',
  },
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: t('productPurchase.table.nf'),
    dataIndex: 'nf',
    key: 'nf',
    width: 200,
    ...getColumnSearchProps('nf'),
  },
  {
    title: t('productPurchase.table.createdAt'),
    dataIndex: 'createdAtFormatted',
    key: 'createdAtFormatted',
    width: 250,
    sorter: (a, b) => a.createdAt > b.createdAt,
    ...getColumnSearchProps('createdAt'),
  },
  {
    title: t('productPurchase.table.approvedAt'),
    dataIndex: 'approvedAtFormatted',
    key: 'approvedAtFormatted',
    width: 250,
    align: 'center',
    sorter: (a, b) => a.approvedAt > b.approvedAt,
    ...getColumnSearchProps('approvedAt'),
  },
  {
    title: t('productPurchase.table.value'),
    dataIndex: 'value',
    key: 'value',
    align: 'right',
    width: 200,
  },
  {
    title: '',
    dataIndex: 'action',
    className: 'nowrap',
    align: 'right',
    width: 50,
  },
])

const mapStateToProps = ({ productPurchase, products, units }) => ({
  productPurchase,
  units,
  products,
})
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...actionLogin,
  ...actionsUnits,
  fetchProducts: productsActions.fetchProducts,
}, dispatch)

class ListProductPurchase extends Component {
  state = {
    selectedPurchase: null,
    openModal: false,
    searchResult: null,
    newPurchase: {
      type: '2',
      products: [],
    },
  }

  componentDidMount() {
    const {
      units,
      products,
      match,
      fetchProductPurchase,
      fetchUnits,
      fetchProducts,
    } = this.props
    const unitId = match.params.id

    if (!units.data) {
      fetchUnits()
    }

    if (!products.data) {
      fetchProducts(unitId)
    }

    fetchProductPurchase(unitId)
  }

  toggleModal = (id = null) => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
      selectedPurchase: id,
      newPurchase: {
        type: '2',
        products: [],
      },
      searchResult: null,
    }))
  }

  setDataSource = data => data.map(item => ({
    ...item,
    value: c(item.value),
    typeFormatted: (
      <Tooltip
        placement="top"
        title={t(`productPurchase.modal.form.options.${item.type}`)}
      >
        <Badge color={status(item.type)} />
      </Tooltip>
    ),
    createdAtFormatted: moment(item.createdAt).format('L LTS'),
    approvedAtFormatted: item.approvedAt
      ? moment(item.approvedAt).format('L LTS')
      : '-',
    action: (
      <Button icon="arrow-right" onClick={() => this.toggleModal(item)} />
    ),
  }))

  handleChanges = (name, value) => {
    this.setState((prevState) => {
      const valuesFormatted = {
        products: [],
      }
      const { products } = prevState.newPurchase

      if (name === 'type') {
        valuesFormatted.type = value
      } else if (+value !== 0) {
        valuesFormatted.products = [{
          product: name.productId,
          quantity: value === '' ? 0 : +value,
        }]
      }

      return {
        newPurchase: {
          ...prevState.newPurchase,
          ...valuesFormatted,
          products: [
            ...products.filter(item => (
              item.product !== name.productId
            )),
            ...valuesFormatted.products,
          ],
        },
      }
    })
  }

  handleSubmit = () => {
    const { sendProductPurchase, match } = this.props
    const { newPurchase } = this.state
    const unitId = match.params.id

    sendProductPurchase(newPurchase, { unitId, after: this.toggleModal })
  }

  onSearch = (term) => {
    const { products: { data } } = this.props

    this.setState({
      searchResult: search(term, data),
    })
  }

  render() {
    const {
      openModal,
      selectedPurchase,
      newPurchase,
      searchResult,
    } = this.state
    const {
      productPurchase,
      units,
      products,
      match,
    } = this.props
    let unitEmail = ''

    if (units.data && match.params.id) {
      unitEmail = units.data.find(unit => unit.id === +match.params.id).email
    }

    return (
      <Card title={`${t('productPurchase.title')}: ${unitEmail}`}>
        <div className="add-action">
          <Button
            type="primary"
            icon="plus"
            onClick={() => this.toggleModal()}
          >
            { t('productPurchase.addNew') }
          </Button>
        </div>
        <div className="list">
          <TableComponent
            rowKey="id"
            columns={columns()}
            loading={openModal || !!productPurchase.loading}
            dataSource={this.setDataSource(productPurchase.data || [])}
          />
        </div>
        <Modal
          title={
            t(`productPurchase.modal.${selectedPurchase ? 'title' : 'add'}`)
          }
          width="60%"
          visible={openModal}
          onCancel={() => this.toggleModal()}
          footer={false}
          className="product-purchase"
          keyboard={!!selectedPurchase}
        >
          <div
            className={
              selectedPurchase
                ? 'product-purchase-content' : 'add-product-purchase-content'
            }
          >
            {
              selectedPurchase && (
                <List
                  size="small"
                  bordered
                  dataSource={selectedPurchase.products}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta title={item.name} />
                      {
                        `${
                          t('productPurchase.modal.quantity')
                        }: ${
                          item.quantity
                        }
                      `}
                    </List.Item>
                  )}
                />
              )
            }
            {
              !selectedPurchase && openModal && (
                <AddNew
                  newPurchase={newPurchase}
                  products={searchResult || products.data}
                  handleChanges={(name, value) => (
                    this.handleChanges(name, value)
                  )}
                  handleSubmit={() => this.handleSubmit()}
                  loading={productPurchase.loading}
                  search={term => this.onSearch(term)}
                />
              )
            }
          </div>
        </Modal>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProductPurchase)
