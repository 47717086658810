import React from 'react'
import { t } from 'ab18n'
import {
  Button,
  List,
  Select,
  Input,
} from 'antd'

import RenderList from 'components/RenderList'

const getProductQuantity = (products, data) => {
  const product = products.filter(item => item.product === data.productId)

  return product.length > 0 ? product[0].quantity : 0
}

const AddNew = ({
  products,
  newPurchase,
  handleChanges,
  handleSubmit,
  search,
  loading,
}) => (
  <div className="add-purchase">
    <div className="type">
      <span>{ t('productPurchase.modal.form.type') }</span>
      <Select
        onChange={e => handleChanges('type', e)}
        value={newPurchase.type}
      >
        <Select.Option value="2">
          { t(`productPurchase.modal.form.options.${2}`) }
        </Select.Option>
        <Select.Option value="-2">
          { t(`productPurchase.modal.form.options.${'-2'}`) }
        </Select.Option>
      </Select>
    </div>
    <div className="list-products">
      <div className="title">
        { t('productPurchase.modal.form.products') }
        <Input.Search
          placeholder={t('productPurchase.modal.filter')}
          onChange={e => search(e.target.value)}
        />
      </div>
      <div className="content">
        {
          products && (
            <List
              size="small"
              dataSource={products}
              renderItem={item => (
                <RenderList
                  {...item}
                  id={item.productId}
                  value={getProductQuantity(newPurchase.products, item)}
                  width={150}
                  type="number"
                  handleInputChange={handleChanges}
                />
              )}
            />
          )
        }
      </div>
      <div className="footer">
        <Button
          type="primary"
          loading={loading}
          onClick={() => handleSubmit()}
        >
          { t('productPurchase.modal.form.submit') }
        </Button>
      </div>
    </div>
  </div>
)

export default AddNew
