import React, { Component, Fragment } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ab18n'
import {
  Layout, Menu, Dropdown, Icon,
} from 'antd'

import {
  Coupons,
  CouponsTabs,
  CouponsAdd,
  Products,
  ProductsPrices,
  Services,
  ServicesPrices,
  Combos,
  CombosPrices,
  ProductPurchase,
  ListProductPurchase,
  NotFound,
  Customers,
  Passwords,
} from 'pages'
import { actions } from 'pages/auth/actions'
import logo from 'assets/images/logo.png'
import './BaseLayout.sass'

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

const { Content } = Layout

const Home = () => (
  <Fragment>
    <h1>{ t('home.title') }</h1>
    <p>
      <Link to="coupons">{ t('home.coupons') }</Link>
    </p>
    <p>
      <Link to="customers">{ t('home.customers') }</Link>
    </p>
    <p>
      <Link to="products">{ t('home.products') }</Link>
    </p>
    <p>
      <Link to="services">{ t('home.services') }</Link>
    </p>
    <p>
      <Link to="combos">{ t('home.combos') }</Link>
    </p>
    <p>
      <Link to="reset-passwords">{ t('home.reset') }</Link>
    </p>
    <p>
      <Link to="product-purchase">{ t('home.productPurchase') }</Link>
    </p>
  </Fragment>
)

const HeaderCustom = ({ logout }) => (
  <header className="c-header">
    <div className="c-header-left">
      <div className="c-logo">
        <img src={logo} alt={t('general.logo')} />
      </div>
      <Menu
        mode="horizontal"
        className="c-menu"
        defaultSelectedKeys={['2']}
        style={{ lineHeight: '64px' }}
      >
        <Menu.Item>
          <Link to="/coupons">{ t('menu.coupons') }</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/customers">{ t('menu.customers') }</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/products">{ t('menu.products') }</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/services">{ t('menu.services') }</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/combos">{ t('menu.combos') }</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/reset-passwords">{ t('menu.reset') }</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/product-purchase">{ t('menu.productPurchase') }</Link>
        </Menu.Item>
      </Menu>
    </div>
    <div className="c-menu-right">
      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item onClick={logout}>
              <Icon type="close-circle" theme="filled" /> { t('auth.logout') }
            </Menu.Item>
          </Menu>
        )}
      >
        <span className="ant-dropdown-link c-dropdown">
          <Icon type="user" />
        </span>
      </Dropdown>
    </div>
  </header>
)

class BaseLayout extends Component {
  render() {
    return (
      <Layout>
        <div className="c-header-wrapper">
          <HeaderCustom {...this.props} />
        </div>
        <Content className="c-content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/coupons" component={Coupons} />
            <Route exact path="/coupons/:id" component={CouponsTabs} />
            <Route exact path="/coupons/:id/add" component={CouponsAdd} />
            <Route exact path="/customers" component={Customers} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/products/:id" component={ProductsPrices} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/services/:id" component={ServicesPrices} />
            <Route exact path="/combos" component={Combos} />
            <Route exact path="/combos/:id" component={CombosPrices} />
            <Route exact path="/product-purchase" component={ProductPurchase} />
            <Route
              exact
              path="/product-purchase/:id"
              component={ListProductPurchase}
            />
            <Route exact path="/reset-passwords" component={Passwords} />
            <Route component={NotFound} />
          </Switch>
        </Content>
      </Layout>
    )
  }
}

export default connect(null, mapDispatchToProps)(BaseLayout)
