import { typeActions } from 'state/util'

const { actions, types } = typeActions('productPurchase', {
  fetchProductPurchase: unitId => ({ meta: { unitId } }),
  setAll: (data = null, error = null) => ({
    payload: data,
    error,
  }),
  sendProductPurchase: (data, meta) => ({ payload: data, meta }),
  setLoading: value => ({ meta: { loading: value } }),
})

export {
  actions,
  types,
}
