import React, { Component } from 'react'
import { List, Input } from 'antd'
import { c, n } from 'ab18n'

class RenderList extends Component {
  state = { value: '' }

  componentDidMount() {
    const { value, type } = this.props
    this.handleChange(value, type)
  }

  componentDidUpdate(prevProps) {
    const { value, type } = this.props

    if (prevProps.value !== value) {
      this.handleChange(value, type)
    }
  }

  handleChange(value, type) {
    let currentValue = value

    if (type === 'currency') {
      currentValue = c(n(value))
    }

    if (type === 'number') {
      currentValue = typeof value === 'string'
        ? value.replace(/\D/g, '')
        : value
    }

    this.setState({ value: currentValue })
  }

  render() {
    const {
      id,
      name,
      info,
      type,
      width,
      handleInputChange,
    } = this.props
    const { value } = this.state

    return (
      <List.Item>
        <List.Item.Meta title={`${id} - ${name}`} />
        <div className="list-item">
          { info && <span className={info.type}>{ info.message }</span> }
          <Input
            size="small"
            style={{ width, textAlign: 'right' }}
            value={value}
            defaultValue={value}
            onChange={e => this.handleChange(e.target.value, type)}
            onBlur={e => handleInputChange(this.props, e.target.value)}
          />
        </div>
      </List.Item>
    )
  }
}

export default RenderList
